import React from 'react';


import Skillcard from './../Skillcard';
import { motion } from 'framer-motion'
import skills from './../data/about_data'
import './about.css'

const About = () => {
    const about_variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.2, duration: 0.6,
            }
        },
        exit: {
            opacity: 0,
            transition: {
                ease: 'easeInOut'
            }
        }
    }
    return (
        <motion.div className="about"

            variants={about_variants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <h6 className="about__intro">
            Supportive and communicative Junior Developer with a background in customer service and management. Having moved to three different countries during my childhood, I possess exceptional problem-solving skills, a knack for adaptability, and a strong sense of ethics. These traits blend seamlessly with my ability to facilitate and continuous need for self-improvement to form the basis of my work style
            </h6>
            <div className="container about__container">
                <h6 className="about__heading">Skills</h6>
                <div className="row">
                    {
                        skills.map(skill =>
                            <Skillcard skill={skill} />

                        )
                    }
                </div>
            </div>
        </motion.div>
    );
};

export default About;